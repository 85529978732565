import Ras from "../../components/ras/Ras";
import Cristal from "./../../components/cristal/Cristal";
import Ospoce from "./../../components/ospoce/Ospoce";
import Swiss from "./../../components/swiss/Swiss";
import Team from "../../components/team/Team";

function Planes() {
  return (
    <div className="container mx-auto">
      <div className="text-center">
        <h1 className="text-4xl font-medium">Conocé nuestros planes</h1>
        <h2 className="text-2xl pb-6">Elegí el que más te convenga.</h2>
      </div>
      <div className="px-6">
        <p className="text-2xl">
          OSDI cuenta con una amplia selección de profesionales médicos y
          centros asistenciales distribuidos por todo el país a los cuales podrá
          acceder a través de nuestros diferentes planes.
        </p>
      </div>
      <div className="grid lg:grid-cols-2">
        <div className="flex flex-col">
          <Ospoce />
          <Swiss />
        </div>
        <div className="flex flex-col">
          <Cristal />
          <Ras />
        </div>
      </div>
      <Team />
    </div>
  );
}
export default Planes;
