import Accordion from "../../components/accordion/Accordion";

function Empresa({ brand, children }) {
  return (
    <section className="flex flex-col">
      <div className="items-center p-4">
        <div className="xl:w-6/8 px-6 border rounded-xl shadow-lg h-full">
          <div className="h-24 flex items-center justify-center">
            <img width="200px" className="select-none" src={brand} alt="Logo" />
          </div>
          <Accordion>{children}</Accordion>
        </div>
      </div>
    </section>
  );
}
export default Empresa;
