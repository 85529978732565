function Footer() {
  return (
    <footer className="mt-auto">
      <div className="flex flex-col w-full pt-12">
        <div className="sm:items-center justify-evenly border-y flex flex-col lg:flex-row py-4">
          <h1 className="px-6 sm:px-10 text-2xl sm:text-3xl font-semibold text-left sm:max-w-lg break-words">
            Comprometidos con tu salud y cuidando de tu bienestar.
          </h1>
          <div className="flex flex-col sm:gap-x-4 sm:flex-row text-lg sm:text-xl p-6">
            <ul className="flex flex-col px-4 pb-4 sm:w-1/2">
              <li className="font-semibold pb-2 sm:max-w-xs break-words">
                Servicios
              </li>
              <li>
                <a
                  href="/nosotros"
                  className="hover:text-[#FFCC00] transition-colors duration-300 ease-in-out whitespace-nowrap"
                >
                  Conocé Osdi
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="hover:text-[#FFCC00] transition-colors duration-300 ease-in-out whitespace-nowrap"
                >
                  Formulario de afiliación
                </a>
              </li>
              <li>
                <a
                  href="/turismo"
                  className="hover:text-[#FFCC00] transition-colors duration-300 ease-in-out"
                >
                  Turismo
                </a>
              </li>
            </ul>
            <ul className="flex flex-col px-4 sm:w-1/2">
              <li className="font-semibold pb-2 sm:max-w-xs break-words">
                Obra Social Digital
              </li>
              <li>
                <a
                  href="/contacto"
                  className="hover:text-[#FFCC00] transition-colors duration-300 ease-in-out"
                >
                  Contacto
                </a>
              </li>
              <li>
                <a
                  href="/información"
                  className="hover:text-[#FFCC00] transition-colors duration-300 ease-in-out"
                >
                  Información
                </a>
              </li>
              <li>
                <a
                  href="/centros-de-atención"
                  className="hover:text-[#FFCC00] transition-colors duration-300 ease-in-out"
                >
                  Centros de atención
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap justify-center sm:justify-evenly items-center py-6 gap-2 sm:gap-4 text-center">
          <p className="opacity-75 text-md sm:text-lg px-4 sm:px-0">
            <strong>
              2025 © Copyright Osdi. Todos los derechos reservados.
            </strong>
          </p>
          <p className="text-md sm:text-lg px-4 sm:px-0">
            Desarrollado por{" "}
            <a
              href="https://vgl.com.ar/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#127A3D] transition-colors duration-300 ease-in-out font-semibold"
            >
              VGL
            </a>{" "}
            &{" "}
            <a
              href="https://bgonzalezloureiro.com.ar/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#5A0FA6] transition-colors duration-300 ease-in-out font-semibold"
            >
              BGL
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
