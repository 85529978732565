const FAQItem = ({ question, answer }) => {
  return (
    <div className="container mb-6">
      <div className="flex flex-row items-center">
        <div className="pr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#3ecf8e"
          >
            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
          </svg>
        </div>
        <h3 className="text-2xl text-[#1a659e] font-semibold text-left">
          {question}
        </h3>
      </div>
      <p className="text-xl text-left">{answer}</p>
    </div>
  );
};

const Informacion = () => {
  const faqData = [
    {
      question: "¿Dónde están ubicados?",
      answer: "Nuestra oficina central está ubicada en Viamonte 611, CABA.",
    },
    {
      question:
        "¿Cómo realizo una Inclusión o modificación en mi grupo familiar?",
      answer:
        "Para adherir a un hijo o cónyuge deberás presentar: DNI, partida de nacimiento / certificado de matrimonio o concubinato según corresponda, para acreditar parentesco y CUIL. Recordá que el valor del plan es por persona y la inclusión modificará el valor total de tu plan.",
    },
    {
      question: "Cambié de trabajo, ¿debo informarlo?",
      answer:
        "Si. Dado que tu plan está sujeto a un aporte, si realizás cambios en tu relación laboral siempre deberás informarlo para evitar interrupciones en tu cobertura. Enviá tu alta temprana o nuevo recibo de sueldo a OSDI.",
    },
    {
      question: "¿Cómo realizo un cambio de plan?",
      answer:
        "En caso de querer bajar o subir de plan deberás contactarte con OSDI. El trámite se puede realizar de manera online o en nuestra oficina. Recordá que es requisito que lo solicite el titular.",
    },
    {
      question: "Me despidieron de mi trabajo, ¿hasta cuándo tengo cobertura?",
      answer:
        "Vas a tener cobertura de nuestro PMO (Plan Médico Obligatorio) durante los 3 meses posteriores a la fecha de cese de actividades. Para acceder a la cobertura deberás enviar el telegrama de despido a OSDI.",
    },
  ];

  return (
    <div className="flex justify-center items-center px-8">
      <div className="w-full max-w-3xl">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-medium">Información</h1>
          <h2 className="text-2xl pb-6">Consultas administrativas</h2>
        </div>
        <div>
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Informacion;
