function CentrosDeAtencion() {
  const branches = [
    {
      id: 1,
      name: "Clínica Suizo Argentina",
      address: "Av. Pueyrredón 1461",
      image:
        "https://www.swissmedical.com.ar/clinewsite/images/contenido/cymsa/instalaciones/imagen_g1.jpg",
    },
    {
      id: 2,
      name: "Clínica Zabala",
      address: "Av. Cabildo 1295",
      image:
        "https://www.swissmedical.com.ar/clinewsite/images/contenido/zabala/instalaciones/imagen_g1.jpg",
    },
    {
      id: 3,
      name: "Clínica Bazterrica",
      address: "Juncal 3002",
      image:
        "https://www.amffa.com.ar/wp-content/uploads/2023/07/basterrica.jpg",
    },
    {
      id: 4,
      name: "Clínica Adventista",
      address: "Estomba 1710",
      image:
        "https://whope.com.ar/img/medical_center/f0b051a9920dc7678c238a49219cbedd.png",
    },
    {
      id: 5,
      name: "Clínica San Camilo",
      address: "Avenida Angel Gallardo 899",
      image:
        "https://clinica-web.com.ar/wp-content/uploads/2017/06/clinica-san-camilo.jpg",
    },
    {
      id: 6,
      name: "Sanatorio de la Trinidad",
      address: "Av. Carlos Pellegrini 431",
      image:
        "https://blog.elegimejor.net/wp-content/uploads/2017/11/SanatorioTrinidad-Galeno.jpg",
    },
  ];

  return (
    <section className="text-center px-6">
      <h1 className="text-4xl font-medium">Conocé nuestros centros</h1>
      <h2 className="text-2xl pb-6">Encontrá tu centro más cercano.</h2>
      <div className="grid gap-y-10 gap-x-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-6xl mx-auto">
        {branches.map((branch) => (
          <div
            key={branch.id}
            className="bg-white border shadow-lg rounded-xl overflow-hidden w-full max-w-md mx-auto"
          >
            <div className="relative w-full h-48 cursor-pointer transform transition-transform duration-500 hover:scale-105">
              <img
                src={branch.image}
                alt={branch.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-[#1a659e]">
                {branch.name}
              </h3>
              <p className="text-lg text-gray-700">{branch.address}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default CentrosDeAtencion;
