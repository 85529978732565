import "animate.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Brands from "../brands/Brands";
import "./_banner.scss";

function Banner({ brand }) {
  return <Brands />;
}

export default Banner;
