import { Link } from "react-router-dom";
import uno from "../../assets/img/centers/logo-1.png";
import dos from "../../assets/img/centers/logo-2.png";
import tres from "../../assets/img/centers/logo-3.png";
import cuatro from "../../assets/img/centers/logo-4.png";
import cinco from "../../assets/img/centers/logo-5.png";
import seis from "../../assets/img/centers/logo-6.png";
import siete from "../../assets/img/centers/logo-7.png";
import ocho from "../../assets/img/centers/logo-8.png";
import nueve from "../../assets/img/centers/logo-9.png";
import diez from "../../assets/img/centers/logo-10.png";
import once from "../../assets/img/centers/logo-11.png";
import doce from "../../assets/img/centers/logo-12.png";
import trece from "../../assets/img/centers/logo-13.png";
import catorce from "../../assets/img/centers/logo-14.png";
import quince from "../../assets/img/centers/logo-15.png";
import dieciseis from "../../assets/img/centers/logo-16.png";
import diecisiete from "../../assets/img/centers/logo-17.png";
import dieciocho from "../../assets/img/centers/logo-18.png";

const brands = [
  uno,
  dos,
  tres,
  cuatro,
  cinco,
  seis,
  siete,
  ocho,
  nueve,
  diez,
  once,
  doce,
  trece,
  catorce,
  quince,
  dieciseis,
  diecisiete,
  dieciocho,
];

function Brands() {
  return (
    <Link to="/centros-de-atención">
      <div className="brands-wrapper">
        <div className="brands-container space-x-6 space-y-4 py-10 flex flex-wrap justify-center">
          {brands.map((i, index) => (
            <div key={index} className="brand-item">
              <img
                className="brand-image"
                src={i}
                alt={`brand-logo-${index}`}
              />
            </div>
          ))}
        </div>
      </div>
    </Link>
  );
}

export default Brands;
