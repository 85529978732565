import "animate.css";
import "./_home.scss";

import DashForm from "../../components/dashform/dashForm";
import HomeBoard from "../../components/homeboard/homeBoard";
import Assistcard from "./../../components/assistcard/Assistcard";
import Banner from "./../../components/banner/Banner";
import Team from "./../../components/team/Team";
// import HomeCarousel from "../../components/carousel/HomeCarousel";

function Home() {
  return (
    <>
      <DashForm />
      <HomeBoard />
      <Banner />
      <Assistcard />
      <Team />
      {/* <HomeCarousel /> */}
    </>
  );
}
export default Home;
