import React, { useState } from "react";

function Contacto() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = { name: "", email: "", phone: "" };
    let valid = true;

    if (!formData.name) {
      newErrors.name = "El nombre es obligatorio";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "El email es obligatorio";
      valid = false;
    }

    if (!formData.phone) {
      newErrors.phone = "El teléfono es obligatorio";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Formulario enviado:", formData);
    }
  };

  return (
    <div className="text-center">
      <h1 className="text-5xl font-medium">Contacto</h1>
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto p-6 bg-white border sm:shadow-md rounded-lg"
      >
        <div className="mb-6 relative">
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label
            htmlFor="name"
            className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-800 ${
              formData.name ? "opacity-0" : "opacity-70"
            }`}
          >
            Nombre: *
          </label>
          {errors.name && (
            <span className="text-red-500 text-sm">{errors.name}</span>
          )}
        </div>

        <div className="mb-6 relative">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label
            htmlFor="email"
            className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-800 ${
              formData.email ? "opacity-0" : "opacity-70"
            }`}
          >
            Correo electrónico: *
          </label>
          {errors.email && (
            <span className="text-red-500 text-sm">{errors.email}</span>
          )}
        </div>

        <div className="mb-6 relative">
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label
            htmlFor="phone"
            className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-800 ${
              formData.phone ? "opacity-0" : "opacity-70"
            }`}
          >
            Teléfono: *
          </label>
          {errors.phone && (
            <span className="text-red-500 text-sm">{errors.phone}</span>
          )}
        </div>

        <div className="mb-6 relative">
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            style={{ height: "100px", resize: "none" }}
          />
          <label
            htmlFor="message"
            className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-800 ${
              formData.message ? "opacity-0" : "opacity-70"
            }`}
          >
            Mensaje (opcional):
          </label>
        </div>

        <div className="mt-6 text-right">
          <button
            type="submit"
            className="px-8 py-3 bg-[#1a659e] text-white font-semibold rounded-md transform transition-transform duration-500 hover:scale-105"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contacto;
