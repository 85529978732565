function Actions({
  firstButtonLabel,
  firstButtonUrl,
  secondButtonLabel,
  secondButtonUrl,
  children,
}) {
  const Button = ({ label, url }) => (
    <a href={url || "#"} target="_blank" rel="noreferrer noopener">
      <button className="group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-br to-[#2088e0] from-[#1a659e] items-center justify-center overflow-hidden rounded font-semibold transition hover:scale-105">
        <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]" />
        {label}
      </button>
    </a>
  );

  return (
    <div className="flex flex-col gap-4 select-none">
      {firstButtonLabel && (
        <Button label={firstButtonLabel} url={firstButtonUrl} />
      )}
      {secondButtonLabel && (
        <Button label={secondButtonLabel} url={secondButtonUrl} />
      )}
      {children}
    </div>
  );
}

export default Actions;
