import ContactForm from "../../components/contactform/contactForm";

function Landing() {
  return (
    <>
      <ContactForm />
    </>
  );
}

export default Landing;
