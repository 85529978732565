import Assistcard from "./../../components/assistcard/Assistcard";
import Team from "./../../components/team/Team";

function Turismo() {
  return (
    <>
      <Assistcard />
      <Team />
    </>
  );
}

export default Turismo;
