import { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../logo/Logo";
import "./_menuTW.scss";

function MenuTW() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  return (
    <div className="sm:flex sm:flex-row sm:justify-evenly sm:py-4">
      <div className="flex justify-between items-center">
        <div className="mx-4 sm:mx-auto flex items-center m-6">
          <NavLink to="/">
            <Logo />
          </NavLink>
        </div>
        <button
          onClick={toggleMenu}
          type="button"
          className="sm:hidden focus:outline-none focus:ring-2 focus:ring-sky-300 rounded-lg inline-flex items-center justify-center mr-4 transition-all"
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path
              d={
                menuOpen
                  ? "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  : "M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              }
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <nav
        className={`menu sm:flex sm:items-center transition-all ${
          menuOpen ? "opacity-100" : "opacity-0"
        } sm:opacity-100`}
      >
        <div
          id="mobile-menu"
          className={`transition-all ${
            menuOpen ? "fade-in block" : "fade-out hidden"
          } sm:block`}
        >
          <ul className="flex flex-col sm:flex-row items-center gap-y-2 sm:gap-x-4 py-2 px-2">
            {["planes", "centros-de-atención", "información"].map((item) => {
              const formattedItem = item
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");

              return (
                <li className="p-2" key={item}>
                  <NavLink
                    to={`/${item}`}
                    className={({ isActive }) =>
                      isActive
                        ? "text-[#1a659e] border-b border-[#1a659e] transition-all duration-300"
                        : "text-black hover:text-[#1a659e] transition-colors duration-300 ease-in-out"
                    }
                  >
                    <p>{formattedItem}</p>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default MenuTW;
