import { useNavigate } from "react-router-dom";
import home from "../../assets/img/home.webp";

function DashForm() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/landing");
  };

  return (
    <div className="relative w-full h-[50vh] mb-16">
      <div className="absolute inset-0 bg-black/50"></div>
      <img
        fetchpriority="low"
        src={home}
        className="w-full h-full object-cover"
        alt="Fondo Inicio"
      />
      <div className="title px-4 sm:px-0 absolute inset-0 flex flex-col items-center justify-center text-center">
        <h1 className="font-semibold sm:text-5xl mb-2">
          Formá parte de la salud del futuro.
        </h1>
        <h3 className="font-medium text-2xl sm:text-3xl mb-4 sm:mb-6">
          Nosotros nos encargamos de todo.
        </h3>
        <div className="flex flex-col items-center text-center">
          <button
            className="px-5 py-2.5 relative rounded-lg group font-medium text-white text-xl inline-block"
            onClick={handleRedirect}
            aria-label="Cotizar Plan"
          >
            <span className="absolute top-0 left-0 w-full h-full rounded-lg opacity-50 filter blur-sm bg-gradient-to-br from-[#2088e0] to-[#1a659e]"></span>
            <span className="h-full w-full inset-0 absolute mt-0.5 ml-0.5 bg-gradient-to-br filter group-active:opacity-0 rounded-lg opacity-50 from-[#2088e0] to-[#1a659e]"></span>
            <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-out rounded-lg shadow-xl bg-gradient-to-br filter group-active:opacity-0 group-hover:blur-sm from-[#2088e0] to-[#1a659e]"></span>
            <span className="absolute inset-0 w-full h-full transition duration-200 ease-out rounded-lg bg-gradient-to-br to-[#2088e0] from-[#1a659e]"></span>
            <span className="relative font-medium text-2xl">
              Cotizá tu Plan
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashForm;
