import logoDesktop from "../../assets/img/Osdi.png";
import logoMobile from "../../assets/img/Osdi.png";

function Logo() {
  return (
    <div className="logo flex items-center px-2 sm:px-5">
      <img
        className="mx-auto"
        srcSet={`${logoMobile} 240w,
                  ${logoDesktop} 460w`}
        sizes="(max-width: 640px) 120px,
                  240px"
        src={logoDesktop}
        alt="OSDI Logo"
      />
    </div>
  );
}

export default Logo;
