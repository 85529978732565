import React, { useState } from "react";
import laptop from "../../assets/img/laptop.webp";

const TextInput = ({ label, name, value, handleChange, required }) => (
  <div className="mb-6 relative">
    <label
      htmlFor={name}
      className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-800 ${
        value ? "opacity-0" : "opacity-90"
      }`}
    >
      {label}:
    </label>
    <input
      type="text"
      id={name}
      name={name}
      value={value}
      onChange={handleChange}
      className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required={required}
    />
  </div>
);
const SelectInput = ({ label, name, value, options, handleChange }) => (
  <div className="mb-6 relative">
    <label
      htmlFor={name}
      className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-800 ${
        value ? "opacity-0" : "opacity-90"
      }`}
    >
      {label}:
    </label>
    <select
      name={name}
      value={value}
      onChange={handleChange}
      className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
    email: "",
    tipoDocumento: "dni",
    dni: "",
    edad: "",
    trabajo: "",
    tipoTrabajo: "dependencia",
    lugarResidencia: "caba",
  });

  const [formErrors, setFormErrors] = useState({});

  const formFields = [
    { label: "Nombre", type: "text", name: "nombre", required: true },
    { label: "Apellido", type: "text", name: "apellido", required: true },
    { label: "Teléfono", type: "tel", name: "telefono" },
    { label: "Email", type: "email", name: "email", required: true },
    {
      label: "Tipo de documento",
      type: "select",
      name: "tipoDocumento",
      options: [
        { value: "dni", label: "DNI" },
        { value: "Cuil", label: "Cuil" },
        { value: "Cuit", label: "Cuit" },
        { value: "CL", label: "CL" },
      ],
    },
    { label: "Número de documento", type: "text", name: "dni", required: true },
    { label: "Edad", type: "number", name: "edad", required: true },
    {
      label: "Tipo de trabajo",
      type: "select",
      name: "tipoTrabajo",
      options: [
        { value: "dependencia", label: "Relación de dependencia" },
        { value: "particular", label: "Como particular" },
      ],
    },
    {
      label: "Lugar de residencia",
      type: "select",
      name: "lugarResidencia",
      options: [
        { value: "caba", label: "CABA" },
        { value: "norte", label: "Zona Norte" },
        { value: "sur", label: "Zona Sur" },
        { value: "oeste", label: "Zona Oeste" },
      ],
    },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.nombre) {
      errors.nombre = "El nombre es obligatorio";
    } else if (/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/.test(formData.nombre)) {
      errors.nombre =
        "El nombre no puede contener números ni caracteres especiales";
    }
    if (!formData.apellido) {
      errors.apellido = "El apellido es obligatorio";
    } else if (/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/.test(formData.apellido)) {
      errors.apellido =
        "El apellido no puede contener números ni caracteres especiales";
    }
    if (!formData.email) errors.email = "El email es obligatorio";
    if (!formData.dni) errors.dni = "El DNI es obligatorio";
    if (!formData.edad) errors.edad = "La edad es obligatoria";
    const isValidEmail =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email);
    if (formData.email && !isValidEmail)
      errors.email = "El formato del email es incorrecto";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      console.log(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 sm:p-6">
      <div className="flex flex-col md:flex-row gap-y-8 gap-x-10 pb-6">
        <div className="w-full md:w-1/2">
          <h1 className="m-0 border-l-4 border-[#1a659e] pl-2 text-4xl font-medium mb-6">
            Formulario de Contacto
          </h1>
          <img
            src={laptop}
            alt="Pareja usando una laptop"
            className="rounded-xl shadow-lg w-full h-auto object-cover max-w-full"
          />
        </div>
        <div className="w-full md:w-1/2">
          <form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto p-6 shadow-lg border rounded-xl"
          >
            {formFields.map((field, index) => {
              if (field.type === "select") {
                return (
                  <SelectInput
                    key={index}
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    options={field.options}
                    handleChange={handleChange}
                  />
                );
              } else {
                return (
                  <TextInput
                    key={index}
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    handleChange={handleChange}
                    required={field.required}
                  />
                );
              }
            })}
            <div className="mt-6 text-right">
              <button
                type="submit"
                className="px-8 py-3 bg-[#005b7f] text-white font-semibold rounded-md transform transition-transform duration-500 hover:scale-105"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="container">
        <p className="text-xs leading-relaxed mt-4 mb-4">
          Los datos que ingreses para cotizar un plan médico y/o para solicitar
          tu afiliación serán almacenados en la base de datos de OSDI (sita en
          la Viamonte 611, Ciudad de Buenos Aires) y resguardados según sus
          políticas de seguridad. Existen campos optativos y obligatorios, en
          caso de omisión y/o inexactitud de alguno de los datos obligatorios,
          no podremos realizar la cotización solicitada. La cotización de un
          plan médico y/o la solicitud de afiliación importará autorizar a OSDI
          y a sus sociedades controlantes, controladas y/o de control común y/o
          vinculadas a utilizar los datos no sensibles en campañas de promoción
          de sus productos o servicios. Por último, te informamos que tenés la
          posibilidad de ejercer los derechos de acceso, rectificación y
          supresión de los datos (artículos 6, 14, 16, 17 y concordantes, Ley
          25.326). LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter
          de Órgano de Control de la Ley N° 25.326, tiene la atribución de
          atender las denuncias y reclamos que interpongan quienes resulten
          afectados en sus derechos por incumplimiento de las normas vigentes en
          materia de protección de datos personales. Una vez perfeccionado el
          contrato de acuerdo a lo estipulado precedentemente, el contratante
          podrá, en el término de DIEZ (10) días, revocar el mismo conforme a lo
          dispuesto en el artículo 34 de la Ley N° 24.240.
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
