import MenuTW from "../menu/MenuTW";

function Head() {
  return (
    <header className="header">
      <MenuTW />
    </header>
  );
}

export default Head;
