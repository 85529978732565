import Brand from "../../assets/img/buenosaires.jpg";
import "animate.css";

function Nosotros() {
  return (
    <div className="container mx-auto py-12 px-6">
      <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-12">
        <div className="sm:w-1/3 p-4 mb-8 sm:mb-0">
          <img
            width="300px"
            className="rounded-xl shadow-lg transform transition-transform duration-500 hover:scale-105"
            src={Brand}
            alt="Buenos Aires"
          />
        </div>
        <div className="sm:w-2/3 sm:p-4 text-left space-y-6">
          <h4 className="font-semibold border-l-4 border-[#1a659e] pl-2">
            NUESTRO PLAN ES CUIDARTE
          </h4>
          <h1>
            En <strong>OSDI</strong> pensamos en vos
          </h1>
          <h3 className="text-3xl font-semibold text-gray-800 mb-4">
            Somos una organización formada por profesionales ocupados en brindar
            asesoramiento a medida sobre salud.
          </h3>
          <p className="text-xl sm:text-2xl text-gray-700">
            Entendemos que para cada persona existe un plan con la cobertura
            ideal. Queremos indicarte cuál es la que vos necesitas, sin pagar de
            más y permitiendo una cobertura completa de tus necesidades.
          </p>
          <p className="text-xl sm:text-2xl text-gray-700">
            Nos distingue el compromiso por el seguimiento del producto que
            comercializamos. Cumplimos 15 años apoyándonos en empresas líderes
            para garantizar un servicio postventa adecuado.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
