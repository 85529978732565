import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./normalize.css";

import {
  default as Error,
  default as ErrorPage,
} from "./pages/error/Error.jsx";
import Home from "./pages/home/Home";
import Nosotros from "./pages/nosotros/Nosotros.jsx";
import Planes from "./pages/planes/Planes";
import Root from "./pages/root/Root";
import Informacion from "./pages/informacion/Informacion.jsx";
import Turismo from "./pages/turismo/Turismo.jsx";
import CentrosDeAtencion from "./pages/centros-de-atencion/CentrosDeAtencion.jsx";
import Contacto from "./pages/contacto/Contacto.jsx";
import Landing from "./pages/landing/Landing.jsx";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "planes",
            element: <Planes />,
          },
          {
            path: "información",
            element: <Informacion />,
          },
          {
            path: "centros-de-atención",
            element: <CentrosDeAtencion />,
          },
          {
            path: "nosotros",
            element: <Nosotros />,
          },
          {
            path: "turismo",
            element: <Turismo />,
          },
          {
            path: "contacto",
            element: <Contacto />,
          },
          {
            path: "landing",
            element: <Landing />,
          },
          {
            path: "*",
            element: <Error />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
