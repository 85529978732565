import whatsapp from "../../assets/img/whatsapp.svg";

function Whatsapp() {
  return (
    <div className="fixed bottom-10 right-10 md:right-20 z-50 transform transition-transform duration-500 hover:scale-105">
      <a
        href="https://wa.me/5491172121915?text=%C2%A1Hola!%20Ingres%C3%A9%20por%20la%20p%C3%A1gina%20para%20comunicarme%20con%20ustedes."
        target="_blank"
        rel="noreferrer"
        className="bg-[#25D366] w-16 h-16 rounded-full flex items-center justify-center shadow-lg"
      >
        <img
          src={whatsapp}
          className="w-8 h-8 object-contain"
          alt="WhatsApp Logo"
        />
      </a>
    </div>
  );
}

export default Whatsapp;
