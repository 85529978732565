import { NavLink } from "react-router-dom";

const ButtonLink = ({ to, children, image }) => {
  return (
    <NavLink to={to} className="w-full sm:w-1/3">
      <button
        className="button flex flex-col w-full justify-between items-center text-2xl bg-white border rounded-xl shadow-lg select-none cursor-pointer hover:shadow-xl transition-all duration-300 overflow-hidden group"
        aria-label={`Ir a ${children}`}
      >
        <div className="relative w-full h-32 overflow-hidden">
          <img
            src={image}
            alt={children}
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-white/60 to-transparent"></div>
        </div>
        <div className="p-4 flex justify-between w-full items-center">
          <strong>{children}</strong>
          <span className="flex px-2 transition duration-300 p-4 rotate-90 group-hover:-rotate-90">
            <ArrowIcon />
          </span>
        </div>
      </button>
    </NavLink>
  );
};

const ArrowIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
  >
    <path
      d="M7.5 2C7.77614 2 8 2.22386 8 2.5L8 11.2929L11.1464 8.14645C11.3417 7.95118 11.6583 7.95118 11.8536 8.14645C12.0488 8.34171 12.0488 8.65829 11.8536 8.85355L7.85355 12.8536C7.75979 12.9473 7.63261 13 7.5 13C7.36739 13 7.24021 12.9473 7.14645 12.8536L3.14645 8.85355C2.95118 8.65829 2.95118 8.34171 3.14645 8.14645C3.34171 7.95118 3.65829 7.95118 3.85355 8.14645L7 11.2929L7 2.5C7 2.22386 7.22386 2 7.5 2Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
function HomeBoard() {
  return (
    <div className="container text-center mx-auto px-8 flex flex-col justify-evenly">
      <div className="flex flex-col sm:flex-row justify-evenly gap-y-12 text-[#1a659e]">
        <ButtonLink
          to="/planes"
          image="https://ospoceintegral.com.ar/wp-content/uploads/2023/08/emergencia-medica.jpg"
        >
          PLANES
        </ButtonLink>
        <ButtonLink
          to="/nosotros"
          image="https://ospoceintegral.com.ar/wp-content/uploads/2023/08/banner-8.webp"
        >
          NOSOTROS
        </ButtonLink>
      </div>
    </div>
  );
}

export default HomeBoard;
