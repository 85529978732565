import React from "react";
import whatsapp from "../../assets/img/whatsapp.svg";
import avatar from "../../assets/img/avatar.webp";

const Avatar = ({ src, alt }) => (
  <img
    src={src || avatar}
    alt={alt}
    className="w-16 h-16 rounded-full object-cover"
  />
);

const TeamText = () => (
  <div className="flex flex-col">
    <span className="text-md text-[#464646]">Equipo Comercial</span>
    <span className="text-2xl font-medium text-[#1a659e]">
      <strong>OSDI:</strong> Tu Nueva Forma de Cuidarte
    </span>
  </div>
);

const ChatButton = () => (
  <a
    href="https://wa.me/5491172121915?text=%C2%A1Hola!%20Ingres%C3%A9%20por%20la%20p%C3%A1gina%20para%20comunicarme%20con%20ustedes."
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-3 px-6 py-3 bg-[#004b75] text-white rounded-lg text-lg font-semibold shadow-md hover:bg-[#1a659e] hover:shadow-lg transition-all duration-300"
  >
    <span
      className="whatsapp-icon w-5 h-5 bg-cover"
      style={{ backgroundImage: `url(${whatsapp})` }}
    ></span>
    Chateá Ahora
  </a>
);

const Team = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col gap-y-6 md:flex-row items-center justify-between bg-white p-6 border rounded-xl shadow-lg max-w-4xl mx-auto">
        <div className="flex items-center gap-6">
          <Avatar src={avatar} alt="Perfil" />
          <TeamText />
        </div>
        <ChatButton />
      </div>
    </div>
  );
};

export default Team;
